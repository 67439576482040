import { useState } from "react"

import { SearchFilter } from "src/components/Filter/SearchFilter"
import { Pager } from "src/components/Pager/Pager"
import { useUrlPager } from "src/components/Pager/useUrlPager"
import { ParadiseCreateClientDialog } from "src/components/Paradise/ParadiseClients/ParadiseCreateClientDialog/ParadiseCreateClientDialog"
import { ParadiseTable } from "src/components/Paradise/ParadiseTable"
import {
  CenterText,
  FilterWrapper,
  ParadiseLayout,
  ParadiseTitle,
  TopWrapper,
} from "src/components/Paradise/sharedStyles"
import { useFetchApiClients } from "src/data/apiClients/apiClientQueries"
import { IApiClient } from "src/data/apiClients/apiClientTypes"
import { OwnerType } from "src/data/integrations/types/integrationTypes"
import { Routes } from "src/router/routes"
import { useRouter } from "src/router/useRouter"
import { useSearchParams } from "src/router/useSearchParams"
import { MButton } from "src/ui/Button/MButton"
import { DropdownMultiSelect } from "src/ui/DropdownSelect/DropdownMultiSelect"
import {
  TableColumn,
  useTableColumns,
} from "src/ui/GridTable/useTableColumns/useTableColumns"
import { Titlebar } from "src/ui/Layout/Titlebar"
import { InternalLink } from "src/ui/Link/InternalLink"
import { MBadge } from "src/ui/MBadge/MBadge"
import { spacing } from "src/ui/spacing"
import { formatDate } from "src/utils/l10n"

const tableColumns: TableColumn<IApiClient>[] = [
  {
    label: "Client id",
    value: "client_id",
    disabled: true,
    columnWidth: "auto",
    disableClickPropagation: true,
    render: (data) => (
      <InternalLink to={Routes.ParadiseClient.location(data.client_id)}>
        {data.client_id}
      </InternalLink>
    ),
  },
  {
    label: "Name",
    value: "name",
    disabled: true,
    columnWidth: "auto",
    render: (data) => <div>{data.name || "-"}</div>,
  },
  {
    label: "Description",
    value: "description",
    columnWidth: "auto",
    render: (data) => <div>{data.description || "-"}</div>,
  },
  {
    label: "Owner",
    value: "owner",
    columnWidth: "auto",
    disableClickPropagation: true,
    render: (data) => (
      <>
        {data.owner.type === OwnerType.ORGANIZATION ? (
          <InternalLink
            to={Routes.ParadiseOrganization.location(data.owner.id)}
          >
            {data.owner.id}
          </InternalLink>
        ) : (
          <InternalLink to={Routes.ParadiseUser.location(data.owner.id)}>
            {data.owner.id}
          </InternalLink>
        )}
      </>
    ),
  },
  {
    label: "Created at",
    value: "created_at",
    columnWidth: "auto",
    render: (data) => (
      <div>{formatDate({ date: data.created_at, timezone: "utc" })}</div>
    ),
  },
  {
    label: "",
    value: "enabled",
    columnWidth: "min-content",
    disabled: true,
    render: (data) => (
      <CenterText>
        <MBadge size="x-small" color={data.enabled ? "good" : "info"}>
          {data.enabled ? "Enabled" : "Disabled"}
        </MBadge>
      </CenterText>
    ),
  },
]

export function ParadiseClients() {
  const [showCreateClientDialog, setShowCreateClientDialog] = useState(false)

  const { navigate } = useRouter()

  const { offset, setOffset, limit } = useUrlPager({ initialLimit: 50 })

  const { searchParams: filter, setSearchParams: setFilter } = useSearchParams({
    keys: [
      {
        key: "client_id",
        type: "string",
      },
    ],
  })

  const fetchApiClients = useFetchApiClients({
    offset,
    limit,
    client_id: filter.client_id || undefined,
    options: {
      keepPreviousData: true,
    },
  })

  const {
    headerElements,
    rows,
    templateColumns,
    interactiveColumns,
    interactiveVisibleColumns,
    setColumnVisibility,
  } = useTableColumns({
    columns: tableColumns,
    data: fetchApiClients.data?.clients,
    options: { localStorageKey: "minut.paradise.apiclients.table" },
  })
  return (
    <ParadiseLayout>
      <Titlebar
        bottomMargin={spacing.S}
        title={
          <ParadiseTitle>
            API clients
            {fetchApiClients.isSuccess && (
              <MBadge color="info">
                Total:
                {fetchApiClients.data?.paging.total_count}
              </MBadge>
            )}
          </ParadiseTitle>
        }
        actionBar={
          <div>
            <MButton
              size="small"
              onClick={() => setShowCreateClientDialog(true)}
            >
              Create client
            </MButton>
          </div>
        }
        size="unset"
      />
      <TopWrapper>
        <FilterWrapper>
          <SearchFilter
            initialValue={filter.client_id ?? undefined}
            onChange={(value) => {
              setOffset(0)
              setFilter("client_id", value)
            }}
            placeholder="Search by client id"
          />
        </FilterWrapper>
        <div>
          <DropdownMultiSelect
            label="Columns"
            options={interactiveColumns}
            selectedValues={interactiveVisibleColumns.map((c) => c.value)}
            onChange={({ checked, option }) => {
              setColumnVisibility(option.value, !checked)
            }}
          />
        </div>
      </TopWrapper>
      <ParadiseTable
        header={headerElements}
        rows={rows ?? []}
        templateColumns={templateColumns}
        onRowClick={(index) => {
          if (fetchApiClients.data?.clients) {
            navigate(
              Routes.ParadiseClient.location(
                // @ts-expect-error: noUncheckedIndexedAccess
                fetchApiClients.data.clients[index].client_id
              )
            )
          }
        }}
      />
      <Pager
        limit={limit}
        offset={offset}
        setOffset={setOffset}
        totalCount={fetchApiClients.data?.paging.total_count}
      />
      <ParadiseCreateClientDialog
        open={showCreateClientDialog}
        onClose={() => setShowCreateClientDialog(false)}
      />
    </ParadiseLayout>
  )
}
